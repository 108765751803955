import { PayloadAction, Action } from '../../types'
import { Task } from 'redux-saga'
import * as effects from 'redux-saga/effects'
import axios, { CancelToken } from 'axios'

export function* takeLatestByNym<A extends PayloadAction<any, any>>(
  actionType: A['type'] | A['type'][],
  selector: (_: A['payload']) => string,
  worker: (_: A, _t: CancelToken) => any
) {
  const tasks: Record<string, Task> = {}
  while (true) {
    const a: A = yield effects.take(actionType)
    const nym = selector(a.payload)
    if (tasks[nym]) {
      yield effects.cancel(tasks[nym])
    }
    const task = yield effects.fork(withRequestCancellation(worker), a)
    tasks[nym] = task
  }
}

export const withRequestCancellation = <A extends Action<string>>(
  worker: (_: A, _t: CancelToken) => any
) =>
  function* (a: A) {
    const cancelSource = axios.CancelToken.source()
    try {
      yield worker(a, cancelSource.token)
    } finally {
      if (yield effects.cancelled()) {
        yield effects.call(cancelSource.cancel)
      }
    }
  }

export function* takeLatest<A extends Action<string>>(
  a: A['type'] | A['type'][],
  worker: (_: A, _t: CancelToken) => any
) {
  yield effects.takeLatest(a, withRequestCancellation(worker))
}
